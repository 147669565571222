import { AbstractControl } from '@angular/forms';
import { props } from '@ngrx/store';
import { FormGroupValue } from '../form.utils';

export function setFormActiveStep<JoinFormStep>() {
  return {
    'Set Form Active Step': props<{ activeStep: JoinFormStep }>(),
  };
}

export function validateFormStep<JoinFormStep>() {
  return {
    'Validate Form Step': props<{ validateStep: JoinFormStep }>(),
    'Validate Form Step Succeeded': props<{ activeStep: JoinFormStep }>(),
  };
}

export function changedForm<JoinForm extends { [K in keyof JoinForm]?: AbstractControl<any> }>() {
  return {
    'Changed Form': props<{ values: FormGroupValue<JoinForm> }>(),
  };
}
